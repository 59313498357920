/* 以下是直接使用antd的class进行修改，相当于默认全局做修改 */
.ant-pagination-total-text {
  color: rgba(0, 0, 0, 0.45);
}
/* 以下是加上了自定义的class，需要将这个class添加到相应的父组件上或者组件或者元素上 */
.pagination-total-left-table .ant-pagination-total-text {
  flex-grow: 1;
}
.ant-table-tbody > tr > .table-col-op {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.modal-overflow-y-auto .ant-modal-body {
  overflow-y: auto;
  max-height: 80vh;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  height: 100%;
  font-size: 14px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
#root {
  height: 100%;
  background: #f0f2f5;
}
/* 文字颜色。antd全局设置了0.85 */
.deep-gray {
  color: rgba(0, 0, 0, 0.25);
}
.gray {
  color: rgba(0, 0, 0, 0.45);
}
.light-gray {
  color: rgba(0, 0, 0, 0.65);
}
/* 头像样式。目前来看我们用不到antd提供的Avatar大部分功能，所以头像上我们自己定义样式。默认大小32，24 80的添加上额外class */
.avatar {
  border-radius: 100%;
  width: 32px;
  height: 32px;
  object-fit: cover;
}
.avatar.size24 {
  width: 24px;
  height: 24px;
}
.avatar.size64 {
  width: 64px;
  height: 64px;
}
.avatar.size80 {
  width: 80px;
  height: 80px;
}
/* 鼠标浮动在上面，颜色变为主题色的图标 */
.hoverable-icon {
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.2s;
}
.hoverable-icon:hover {
  color: #1890ff;
}
