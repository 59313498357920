/* 以下是直接使用antd的class进行修改，相当于默认全局做修改 */

// 这个是antd pagination 总数文字的class
.ant-pagination-total-text {
  color: rgba(0, 0, 0, 0.45);
}

// 修改选中页码样式。背景颜色为主题色(待确认)
// .ant-pagination {
//   .ant-pagination-item-active {
//     background: @primary-color;
//     a {
//       color: #fff;
//     }
//   }
// }

/* 以下是加上了自定义的class，需要将这个class添加到相应的父组件上或者组件或者元素上 */

// 这个是我们自定义的class名，如果需要改变以下样式，请直接在Table组件上添加该class
.pagination-total-left-table {
  // 这个是antd table下pagination的总数的class名
  .ant-pagination-total-text {
    flex-grow: 1;
  }
}

// table-col-op 是自定义的class，专门为表格中需要操作按钮的列定制，主要是因为如果我们在表格列中直接使用按钮的话，就算是link的按钮，也会比一般文字高，造成的结果就是撑高整行，所以我们需要将上下padding置0
.ant-table-tbody > tr > .table-col-op {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

// Modal 过高内部滚动
.modal-overflow-y-auto {
  .ant-modal-body {
    overflow-y: auto;
    max-height: 80vh;
  }
}
