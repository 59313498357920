@import '@/style/vars.less';

.header {
  height: @header-height;
  &__fixed {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    padding: 0 24px;
    height: @header-height;
    line-height: normal;
    color: #fff;
    background: #001529;
  }
}

.logo {
  width: 24px;
  height: 24px;
}

.title {
  margin-left: 6px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
}

.bar {
  margin: 0 15px;
  width: 1px;
  height: 20px;
  background: rgba(255, 255, 255, 0.45);
}

.name {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.icon {
  margin-right: 24px;
  cursor: pointer;
}

.menu {
  margin-left: 40px;
  border-bottom-color: transparent;
  background: transparent;
  :global {
    .ant-menu-item {
      color: rgba(255, 255, 255, 0.85) !important;
      a {
        color: rgba(255, 255, 255, 0.85) !important;
      }
    }
    .ant-menu-item-active {
      &::after {
        border-bottom: 0 !important;
      }
    }
    .ant-menu-item-selected {
      border-bottom: 2px solid @primary-color;
      color: #fff !important;
      &::after {
        border-bottom: 0 !important;
      }
    }
  }
}

.user {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@primary-color: #1890ff;@warning-color: #faad14;