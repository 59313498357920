@import '@/style/vars.less';

.platform {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.middle {
  flex: 1;
  display: flex;
  // min-height: 0;
}

.main {
  overflow-x: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 12px @content-padding @content-padding;
  max-width: calc(100% - @nav-width);
  background: @background;
}

@primary-color: #1890ff;@warning-color: #faad14;