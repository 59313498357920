@import '@/style/vars.less';

.nav {
  flex-shrink: 0;
  width: @nav-width;
  &__fixed {
    overflow-y: auto;
    position: fixed;
    top: @header-height;
    bottom: 0;
    left: 0;
    z-index: 1000;
    width: @nav-width;
    background: #fff;
    box-shadow: 2px 0px 6px 0px rgba(0, 21, 41, 0.12);
  }
}

.menu {
  border-right-color: transparent;
  :global {
    .ant-menu-item,
    .ant-menu-submenu-title {
      padding-left: 16px !important;
    }
    .ant-menu-sub {
      background: #fff;
    }
    .ant-menu-sub .ant-menu-item {
      padding-left: 40px !important;
    }

    // .ant-menu-item:nth-child(4) {
    //   overflow: visible;
    //   position: relative;
    //   margin-top: 32px;
    //   &::before {
    //     content: '';
    //     position: absolute;
    //     left: 16px;
    //     right: 16px;
    //     top: -15px;
    //     border-top: 2px solid rgba(0, 0, 0, 0.15);
    //   }
    // }
  }
}

@primary-color: #1890ff;@warning-color: #faad14;